$point-size: 8px;
$item-size: 64px;

.currency-animation-effect {
  // background-color: rgba(255, 0, 0, 0.25);
  pointer-events: none;
  z-index: 99999;

  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  max-width: 100%;
  height: 100%;

  .currency-label {
    position: absolute;
    z-index: 99;
    top: -80px;
    left: -50%;
    width: 100%;

    font-family: 'Pangram Bold';
    font-size: 69px;
    color: white;
    text-align: center;
    -webkit-text-stroke-width: 5px;
    -webkit-text-stroke-color: #00c9ff;
  }

  .currency-item {
    // pointer-events: auto;
    position: absolute;
    top: 0;
    left: 0;

    .currency-item-icon {
      width: $item-size;
      height: $item-size;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      transform: translate(-$item-size / 2, -$item-size / 2);
    }
  }
}

.currency-animation-point {
  display: none; // todo: comment if we want to debug
  // pointer-events: auto; // todo: uncomment if we want to debug
  position: absolute;
  width: $point-size;
  height: $point-size;
  border-radius: 100%;
  transform: translate(-$point-size / 2, -$point-size / 2);

  &.start {
    background-color: rgba(0, 255, 0, 1);
  }

  &.end {
    background-color: rgba(255, 0, 0, 1);
  }
}
