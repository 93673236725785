#story-game,
.story-game {
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100svw;
  height: 100%;

  // display: none; // todo: hide game for testing purposes
}

:root.desktop {
  #story-game,
  .story-game {
    width: 100svw;
    height: var(--desktop-game-height);

    &.full-height {
      height: var(--desktop-game-height-full);
    }
    &.desktop-gamefeed {
      width: calc(100svw - var(--desktop-sidebar-width) + 20px);
      height: var(--desktop-game-height);
    }
    &.ai-assistant {
      width: calc(100svw - var(--ai-assistant-width) + 20px);
      height: var(--desktop-game-height-full);
    }
  }
}

:root.mobile {
  #story-game,
  .story-game {
    width: 100svw;
    height: 100%;
  }
}
