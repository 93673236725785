$avatar-size: 22px;

// ==============================================================================================================================
// Generic modal content

.modal-game .modal-box .modal-content {
  box-sizing: border-box;
  padding: 16px;

  .modal-content-header {
    position: relative;
    z-index: -1;
    width: 100%;

    box-sizing: border-box;

    .content-gem-container {
      pointer-events: none;
      position: absolute;

      width: 100%;
      height: 360px;

      top: -205px;
      left: 0;

      background-size: 360px;
      background-repeat: no-repeat;
      background-position: center;

      box-sizing: border-box;

      &.locked {
        top: -195px;
        .content-gem {
          top: 117px;
        }
        .content-gem-icon {
          top: 117px;
        }
      }

      &.stars {
        top: -212px;
        .content-gem {
          top: 137px;
        }
      }

      &.create {
        top: -200px;
        .content-gem {
          top: 127px;
        }
      }

      .gem-avatar {
        position: relative;
        z-index: 1;
        margin-left: calc(50% + 24px);
        margin-top: 64px;
      }
    }

    .content-gem {
      position: relative;
      top: 127px;

      width: 110px;
      height: 110px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: auto;
    }

    .content-gem-icon {
      position: relative;
      top: 127px;

      width: 32px;
      height: 32px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: auto;
    }
  }

  .modal-content-body {
    .content-title {
      white-space: pre-wrap;
      font-family: 'Pangram Bold';
      font-size: 32px;
      padding-bottom: 4px;
      box-sizing: border-box;
    }

    .content-subtitle {
      white-space: pre-wrap;
      font-family: 'Pangram Bold';
      font-size: 18px;
      padding-bottom: 16px;
    }

    .content-message {
      white-space: pre-wrap;
      font-family: 'Pangram Medium';
      font-size: 16px;
      line-height: 1.3em;
      padding-bottom: 40px;
      color: var(--text-color-75);
    }
  }

  .modal-content-footer {
    box-sizing: border-box;
  }
}

// ==============================================================================================================================
// MOBILE

// mobile - full screen modals (not being used anymore)
:root.mobile {
  .modal-game .modal-box {
    pointer-events: auto;
    width: 100%;
  }

  .modal-game .modal-box .modal-content {
    text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
  }
}

// mobile - drawers
:root.mobile {
  .modal-game.drawer {
    justify-content: flex-end;

    .modal-box {
      background-color: var(--navbar-color);
      backdrop-filter: blur(var(--blur-amount));
      opacity: 0;
      transform: translateY(100px);
      padding-bottom: calc(0px + env(safe-area-inset-bottom));

      // border-radius: 40px 40px 0 0;
      border-radius: 32px 32px 0 0;

      border-left: unset;
      border-right: unset;
      border-bottom: unset;

      &.show-anim {
        // note:
        // this should be set to slide-up but for some reason flickers like hell in mobile simulator.
        // leaving comment for reference.
        // animation: slide-up 0.25s ease-out forwards;
        animation: unset;
        opacity: 1;
        transform: translateY(0px);

        &.blur {
          backdrop-filter: unset;
        }
      }

      &.hide-anim {
        // note:
        // this should be set to slide-up but for some reason flickers like hell in mobile simulator.
        // leaving comment for reference.
        // animation: slide-down 0.25s ease-out forwards;
        animation: unset;
        opacity: 0;
        transform: translateY(100px);

        &.blur {
          backdrop-filter: unset;
        }
      }
    }
  }
}

// ==============================================================================================================================
// MODAL ROOT - FULL SCREEN CONTAINER

.modal-game {
  pointer-events: none;
  position: fixed;
  top: 0;
  width: 100%;
  max-width: var(--app-max-width);
  height: 100%;
  min-height: 100svh;
  z-index: 99999;

  background-color: rgba(0, 0, 0, 0.2);
  color: var(--text-color);

  backdrop-filter: blur(0);
  visibility: hidden;
  opacity: 0;

  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;

  transition: opacity 0.25s ease-out, backdrop-filter 0.25s ease-out;

  &.show-anim {
    &.blur {
      // backdrop-filter: blur(var(--blur-amount));
      backdrop-filter: blur(0);
    }

    transition-delay: 0s;
    animation: visible-in 0.25s ease-out forwards;
    opacity: 1;
  }

  &.hide-anim {
    &.blur {
      backdrop-filter: blur(0);
    }

    transition-delay: 0.15s;
    animation: visible-out var(--animation-duration) ease-out forwards;
    opacity: 0;
  }

  .backdrop {
    // this makes the modal a blocking modal
    // the modal will close when tapping in this backdrop
    // background-color: rgba(255, 0, 0, 0.5);
    pointer-events: auto;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// ==============================================================================================================================
// BOX

// when comments is open as a desktop sidebar we need to recenter the modal box in the remaining space
.modal-game.with-sidebar .modal-box {
  position: absolute;
  left: calc(50% - 215px - var(--desktop-sidebar-width) / 2);
}

.modal-game .modal-box {
  align-self: center;

  pointer-events: auto;
  z-index: 1;

  background-color: var(--navbar-color);
  backdrop-filter: blur(var(--blur-amount));

  width: 430px;
  height: fit-content;
  min-height: 160px;

  box-sizing: border-box;

  border-radius: 32px;
  border: 1px solid var(--fill-color);

  opacity: 0;
  transform: scale(90%);

  &.show-anim {
    transition: opacity 0.25s ease-out, transform 0.25s ease-out;
    transition-delay: 0.15s;

    animation: visible-in 0.25s ease-out forwards;
    opacity: 1;
    transform: scale(100%);
  }

  &.hide-anim {
    transition: opacity 0.25s ease-out, transform 0.25s ease-out;
    transition-delay: 0s;

    animation: visible-out 0.25s ease-out forwards;
    opacity: 0;
    transform: scale(90%);
  }
}

// ==============================================================================================================================
// CONTENT

.modal-game .modal-box .modal-content {
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: space-between;

  font-family: 'Pangram Medium';
  font-size: 16px;
  text-align: center;
}

// ==============================================================================================================================
// HEADER

.modal-game .modal-box .modal-content .modal-content-header {
  .title {
    font-family: 'Pangram Bold';
    font-size: 36px;
    margin-bottom: 20px;

    // limit title to 3 lines, otherwise, modals dont fit on SE phones
    max-height: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .subtitle {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 12px;
  }
}

// ==============================================================================================================================
// BODY

// .modal-game .modal-box .modal-content .modal-content-body { }

// ==============================================================================================================================
// FOOTER

.modal-game .modal-box .modal-content .modal-content-footer {
  .modal-drawer-items {
    margin: 20px 24px 8px 24px;

    .holders {
      margin-bottom: 20px;

      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;

      .label {
        font-size: 14px;
        height: $avatar-size;
        line-height: $avatar-size;
        vertical-align: middle;
      }

      .avatars {
        display: flex;

        flex-direction: row;
        margin-top: -3px;

        .avatar-item {
          display: flex;
          flex-direction: row;
          flex: 0.4;
          align-items: center;
          justify-content: center;
          position: relative;

          height: $avatar-size;

          img {
            width: 100%;
          }
        }
      }
    }
  }

  .title {
    font-family: 'Pangram Bold';
    font-size: 32px;
    margin-bottom: 12px;
  }

  .subtitle {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 18px;
    white-space: pre-wrap;

    .label-gold {
      font-family: 'Pangram Bold';
      color: var(--color-gold);
    }
  }

  .drawer-buttons {
    pointer-events: auto;
    display: flex;
    flex-direction: row-reverse;
    justify-items: center;
    justify-content: center;
    gap: 12px;
  }

  .gemz {
    font-size: 14px;
    color: var(--text-color-50);
    margin-bottom: 12px;
  }
}

// ==============================================================================================================================
// Gem Price - horizontal flex layout with labels and currency icon (previously .ethereum className)

.gem-price-container {
  font-size: 32px;
  font-weight: 600;
  line-height: 13px;
  text-decoration: none;
  text-align: center;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  height: fit-content;
  width: fit-content;

  margin: 0 auto;

  .gem-price-label {
    font-family: 'Pangram Bold';
    font-size: 18px;
  }

  .gem-price-icon {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50%;
    width: 20px;
    height: 20px;
    margin: auto;
  }
}

// ==============================================================================================================================
// EXTRA ELEMENTS

.gem-author {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  padding-top: 0px;
  padding-bottom: 8px;

  .gem-author-icon {
    // note that size is defined in GemAuthor component
    width: 24px;
    height: 24px;
  }

  .gem-author-label {
    font-size: 14;
    color: var(--text-color-75);
  }
}

.round-picture {
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
  width: $avatar-size;
  height: $avatar-size;
  border-radius: 100%;
  overflow: hidden;
  margin-left: -8px;
}

#pic-img {
  height: 100%;
  width: initial;
}

// normal onput checkbox based switch

.x-checkbox {
  margin-bottom: 16px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .x-checkbox-label {
    font-family: 'Pangram Bold';
    font-size: 16px;
  }
}

// ios graphical based switch

.x-checkbox-ios {
  margin-bottom: 20px;
  padding: 12px 8px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .x-checkbox-ios-label {
    font-family: 'Pangram Bold';
    font-size: 16px;
  }

  .x-checkbox-ios-switch {
    width: 36px;
    height: 20px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
}

// ==============================================================================================================================
// Item Selector ( - input + )

$item-selector-size: 32px;

.item-selector-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 4px;

  width: 40%;
  height: $item-selector-size;

  .item-selector-input {
    width: calc(100% - $item-selector-size * 2);
    height: 100%;
    box-sizing: border-box;

    font-family: 'Pangram Medium';
    font-size: 15px;
    line-height: 32px;
    text-align: center;

    border-radius: 40px;
    border: 1px solid var(--fill-color);
    background-color: transparent;
    color: var(--text-color);
  }

  .item-selector-button {
    cursor: pointer;
    width: $item-selector-size;
    line-height: $item-selector-size;
    height: 100%;

    border-radius: 40px;
    border: 1px solid var(--fill-color) !important;
  }
}

// ==============================================================================================================================
// Onboarding modals

.onboarding-chest {
  pointer-events: none;
  width: 214px;
  height: 179px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  margin: auto;
  margin-top: -80px;
}

.drawer-content-avatars {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

// ==============================================================================================================================
// Modal Buttons

:root.desktop .button {
  cursor: pointer;
}

.button {
  pointer-events: auto;
  background-color: var(--color-dark);
  text-shadow: none;
  font-family: 'Pangram Bold';
  font-size: 20px;
  height: 56px;
  line-height: 56px;
  border-radius: 40px;
  vertical-align: middle;

  width: 100%;

  // -----------------
  // modal buttons

  &.primary {
    background: linear-gradient(305.83deg, #ffee10 27.33%, #ffb900 86.11%);
    color: var(--color-dark);
    border-radius: 100px;
  }

  &.secondary {
    background-color: var(--text-color);
    color: var(--text-color-inverse);
  }

  &.tertiary {
    background-color: unset;
    border: 1px solid var(--text-color-50);
    color: var(--text-color);
  }

  &.enabled {
    opacity: 1;
    pointer-events: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &.disabled {
    color: var(--text-color-50);
    opacity: 0.5;
    pointer-events: none;
    display: none;
  }
}

// ==============================================================================================================================
// Crafting - must be outside of craft-make class, reused in several places

.craft-ingredients {
  width: 320px;
  height: 100px;
  margin: 0 auto;
  margin-top: 4px;
  margin-bottom: 28px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 16px;

  .craft-icon-plus {
    font-size: 32px;
    margin-bottom: 16px;
    color: white;
    &:last-child {
      display: none;
    }
  }
}

// ==============================================================================================================================
