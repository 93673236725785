/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textField;
}

.wrapper {
  align-items: center;
  justify-content: center;
  width: 100%;
  color: black;
  font-size: 16px;

  // note(cai): This is from old layout before game. Feel free to remove if not used
  // todo(Cai): this completely screws game layouts
  // todo(Cai): i also dont really understand how this wrapper gets added only if you go through onboarding screen
  // @media screen and (min-height: 750px) {
  //   height: 80vh;
  // }
}

.view {
  display: flex;
  flex-direction: column;
  padding: 10px;

  section {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
  }
}

.input-main {
  color: black;
  font-size: 17px;
  border: 2px solid rgb(216, 216, 216);
  border-radius: 100px;
  width: calc(100% - 92px);
  padding: 16px 40px;
  text-align: left;
  cursor: pointer;

  &:focus-visible {
    outline: #0eb2fd;
  }
}

.btn-logout {
  cursor: pointer;
}

.btn-text {
  color: #00cfff;
  font-size: 14px;
  font-weight: bold;
  align-self: flex-end;
  margin-right: 12px;
  text-align: left;
}

.info {
  flex-wrap: wrap;
}

.col {
  flex-direction: column;
}

.row {
  flex-direction: row;
}

// text primary
.text-p {
  font-size: 16px;
  color: black;
}
// text secondary
.text-s {
  font-size: 14px;
  color: rgb(134, 134, 134);
}
// text center
.text-c {
  text-align: center;
}

.disabled,
.off {
  opacity: 0.5;
}

.vGap {
  height: 10px;
}

.vertical-space {
  flex-direction: column;
}

.hide {
  display: none !important;
}

.clickable {
  cursor: pointer;
}

.flex-clr {
  flex: initial !important;
}

.flex-1 {
  flex: 1;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
}

// .view {
//   display: flex;
//   flex-direction: column;
//   width: 100%;
//   height: 100%;
//   flex: 1;
// }

.twitter-x-logo {
  display: inline-block;
  mask-image: url('../assets/logo-twitter-x.svg');
  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: white;
  width: 28px;
  height: 28px;
  vertical-align: baseline;
}

.inviteStep,
.twitterStep,
.baseStep,
.deposit,
.twitterApp {
  max-width: 600px;
  margin: 0 auto;

  .img {
    padding: 60px 0 30px;
    justify-content: flex-start;

    img {
      width: 100px;
    }
  }

  .input-main {
    width: 100%;
    border: 2px solid #ffb900;
    text-align: center;
    background-color: var(--bg-color-50); //  #1c1d20; // todo(Cai): not sure how to test it
    color: #ffb900;
    font-weight: bold;
    &::placeholder {
      color: #5d4b20;
    }
  }

  .title {
    justify-content: flex-start;
    font-size: 24px;
    color: white;
    font-weight: bold;
    display: block;
    margin: 0;
    line-height: 3rem;

    .twitter-x-logo {
      background-color: white;
      width: 20px;
      height: 20px;
    }
  }

  .info {
    color: #d8d8d8;
    display: block;
    line-height: 1.5rem;
    margin: 0 0 10px;

    .twitter-x-logo {
      background-color: #d8d8d8;
      width: 14px;
      height: 14px;
    }
  }

  .content {
    color: #d8d8d8;
    display: block;
    line-height: 1.5rem;
    margin: 0 0 10px;
    font-size: 19px;

    .twitter-x-logo {
      background-color: #d8d8d8;
      width: 16px;
      height: 16px;
    }
  }

  .btns {
    gap: 20px;
  }

  .btn-proceed {
    width: 100%;
  }

  .btn-logout {
    margin-top: 32px;
  }

  .btn-link,
  .btn-deposit,
  .btn-copy-address {
    display: flex;
    align-items: center;
    align-self: flex-end;

    .btn-main {
      font-size: 14px;
      padding: 6px 32px;
    }
  }

  .twitter-x-logo-left-element {
    padding: 10px;
    background-color: black;
    border-radius: 5px;

    .twitter-x-logo {
      background-color: white;
      display: block;
    }
  }
  .ib-text .title {
    color: #d8d8d8;

    .twitter-x-logo {
      background-color: #d8d8d8;
      width: 14px;
      height: 14px;
    }
  }
}

.twitterStep {
  .receiver-sender {
    flex-direction: column;
    cursor: pointer;

    .btn-link {
      display: flex;
      align-items: center;
      align-self: flex-end;
      width: 100%;
      box-sizing: border-box;

      .btn-main {
        font-size: 22px;
        padding: 6px 32px;
        // font-family: 'Pangram Bold', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        // 'Helvetica Neue', sans-serif;
      }
    }
  }
}

.baseStep {
  overflow-y: auto;
  height: 100svh;

  .info {
    display: flex;
  }

  .info-box {
    .btn-main {
      background-color: #26272b;
      color: #d8d8d8;
    }
  }
}

.error {
  color: red;
  margin: 0;
  font-size: 14px;
}

.twitterStep {
  .btn-logout {
    margin-top: 4px;
  }

  .btns {
    flex-direction: column;
    margin-top: 32px;
  }
}

.on {
  opacity: 1;
}

.game-feed-pub {
  .carousel-container {
    .carousel-root {
      .carousel {
        .axis-vertical {
          height: 100% !important;
        }
      }
    }
  }
}
