/* note: hack to overcome the react error overlay. comment this if you want to have it back */
#webpack-dev-server-client-overlay {
  display: none;
}

body {
  font-family: 'Pangram Medium', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
    'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;

  overscroll-behavior: none;

  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;

  background-color: var(--bg-color-body);
  color: var(--text-color);

  overflow: hidden;
}

html {
  /* overflow: hidden; // -> Note that this will screw PWA full screen */
  overscroll-behavior: none;
  margin: 0;
}

div {
  // this fixes semi-transparent blue rectangle
  // appearing in mbile when tapping interactive elements
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@font-face {
  font-family: 'Pangram Medium';
  src: url('../assets/fonts/PPPangramSansRounded-Medium.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pangram Light';
  src: url('../assets/fonts/PPPangramSansRounded-Light.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Pangram Bold';
  src: url('../assets/fonts/PPPangramSansRounded-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
