$safe-top: calc(env(safe-area-inset-top) * 1);

// ======================================================================
// On mobile, deal with currency slots in both feed and profile screens

:root.mobile {
  .topbar {
    .topbar-currencies {
      gap: 4px;

      .topbar-currency-container {
        transform: scale(0.85) translate(-8px, 2px);
      }
    }

    &.right-align {
      .topbar-currencies {
        .topbar-currency-container {
          transform: scale(0.85) translate(12px, 4px);
        }

        .topbar-currency-black {
          background-color: #333;
        }
      }
    }
  }
}

// ======================================================================
// On desktop, deal with currency slots in both feed and profile screens

:root.desktop {
  .topbar {
    .topbar-currencies {
      gap: 24px;
    }

    &.right-align {
      .topbar-currencies {
        right: 28px;
        top: 28px;

        .topbar-currency-black {
          background-color: #333;
        }
      }
    }
  }
}

// ======================================================================
// Topbar

.topbar {
  pointer-events: none;
  z-index: 101; // TODO: Set this on top of the world, but not blocking notifications on mobile
  position: absolute;
  top: var(--padding-info-overlay-y);
  left: var(--padding-info-overlay-x);
  width: calc(100% - var(--padding-info-overlay-x) * 2);
  margin-top: $safe-top;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.right-align {
    top: 0px;
    left: initial;
    right: 0;

    .topbar-currencies {
      position: absolute;
      top: 0;
      right: 0;
      transform: scale(0.85);

      .topbar-currency-black {
        background-color: #333;
      }
    }
  }
}

// ======================================================================
// Currency

.topbar-currencies {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 20px;

  .topbar-currency-container {
    pointer-events: auto;
    cursor: pointer;

    &.show {
      display: 'block';
    }

    &.hide {
      display: 'none';
    }

    &.coin {
      margin-left: 8px;
    }

    // &.diamond {}
  }

  .topbar-currency-black {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    justify-content: left;

    width: fit-content;
    height: 32px;
    padding-right: 14px;
    padding-left: 14px;
    border-radius: 25px;

    background-color: var(--navbar-color-transparent);
    -webkit-backdrop-filter: blur(var(--blur-amount));
    backdrop-filter: blur(var(--blur-amount));
    opacity: 1;

    // box-shadow: var(--box-shadow-window);
  }

  .icon-currency-container {
    position: relative;
    width: 28px;
    height: 40px;
    margin-top: -2px;
    margin-left: 4px;
  }

  .icon-currency {
    background-size: contain;
    background-repeat: no-repeat;
    width: 35px;
    height: 40px;
    margin-left: -19px;
  }

  .icon-currency-plus {
    position: absolute;
    right: 4px;
    bottom: -4px;

    background-size: contain;
    background-repeat: no-repeat;
    width: 26px;
    height: 26px;
  }

  .currency-black-label {
    font-size: 16px;
    font-family: 'Pangram Bold';
    padding-top: 2px;
    margin-left: -8px;
  }
}

// ===================================
// Search

.topbar-search {
  pointer-events: auto;
  cursor: pointer;

  .icon-search-white {
    background-size: contain;
    background-repeat: no-repeat;
    width: 33px;
    height: 33px;

    filter: var(--svg-color-light) drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7));
  }
}

// ===================================
//  Tests

.colorScheme-label {
  margin-top: 10px;
  background: var(--bg-color-body);
  width: 40px;
  text-align: center;
}

.line-test {
  width: 60vw;
  background-color: red;
  height: 1px;
}

.buttons-test {
  z-index: 101;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;

  .button-test {
    display: block;
    visibility: visible;
  }
}
