// -----------------------------

.craft-item {
  // background-color: yellow;
  // padding: 5px;

  cursor: pointer;
  font-family: 'Pangram Medium', sans-serif;
  font-size: 13px;
  text-decoration: none;
  text-align: center;
  color: white;

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;

  .craft-item-content {
    // background-color: red;

    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: var(--size);
    height: var(--size);

    .craft-item-icons-container {
      position: absolute;
      // width: calc(var(--size) * 0.66);
      // height: calc(var(--size) * 0.66);
      width: calc(var(--size) * 1);
      height: calc(var(--size) * 1);

      .craft-item-icon {
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        width: 100%;
        height: 100%;
        margin: auto;

        // drop shadow makes it feel a bit displaced, so let's fix that
        // top: -0.5px;
        // left: -0.5px;
      }
    }

    .craft-item-user-icons-container {
      position: absolute;

      width: calc(var(--size) * 0.35);
      height: calc(var(--size) * 0.35);
      bottom: calc(var(--size) * -2 / 64);
      right: calc(var(--size) * -1 / 64);

      .craft-item-user-icon {
        position: absolute;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: 50%;
        border-radius: 50%;
        width: 100%;
        height: 100%;
      }
    }
  }

  .craft-item-label {
    // background-color: magenta;
    user-select: none;
    font-family: 'Pangram Medium', sans-serif;
    font-size: 13px;
    text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.5);
    width: 100%;
  }
}

// ------------------------------------------
// circular progress bar

.circular-progress-bar {
  position: absolute;

  --size: 64px;
  --half-size: calc(var(--size) / 2);
  --stroke-width: calc(var(--size) * 3.5 / 64); // making the circle stroke bigger or smaller -> base = 3.5
  --radius: calc((var(--size) * 1 - var(--stroke-width)) / 2); // from 1 to 0.9 -> placing the circle a little closer to the icon
  --circumference: calc(var(--radius) * pi * 2);
  --dash: calc((var(--progress) * var(--circumference)) / 100);

  animation: progress-animation 5s linear 0s 1 forwards;
}

.circular-progress-bar circle {
  cx: var(--half-size);
  cy: var(--half-size);
  r: var(--radius);
  stroke-width: var(--stroke-width);
  fill: none;
  stroke-linecap: round;
}

.circular-progress-bar circle.bg {
  stroke: rgba(0, 0, 0, 0.4);
}

.circular-progress-bar circle.fg {
  transform: rotate(-90deg);
  transform-origin: var(--half-size) var(--half-size);
  stroke-dasharray: var(--dash) calc(var(--circumference) - var(--dash));
  transition: stroke-dasharray 0.3s linear 1s;
  stroke: white;
}

@keyframes progress-animation-1 {
  from {
    --progress: 0;
  }
  to {
    --progress: 100;
  }
}

// ------------------------------------------
