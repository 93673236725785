@import './_variables.scss';

// =============================================
// Slide Horizontal from left to right

@keyframes slide-in-left {
  0% {
    transform: translateX(-100px);
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slide-out-left {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(-100px);
    opacity: 0;
    visibility: hidden;
  }
}

@keyframes slide-in-right {
  0% {
    transform: translateX(100px);
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slide-out-right {
  0% {
    transform: translateX(0px);
  }
  100% {
    transform: translateX(100px);
    opacity: 0;
    visibility: hidden;
  }
}

// =============================================
// Slide Vertical

@keyframes slide-up {
  0% {
    transform: translateY(100px);
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slide-down {
  0% {
    transform: translateY(0px);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: translateY(100px);
    opacity: 0;
    visibility: hidden;
  }
}

// =============================================
// Scale

@keyframes scale-up {
  0% {
    transform: scale(100%);
    opacity: 0;
    visibility: visible;
  }
  100% {
    transform: scale(80%);
    opacity: 1;
    visibility: visible;
  }
}

@keyframes scale-down {
  0% {
    transform: scale(80%);
    opacity: 1;
    visibility: visible;
  }
  100% {
    transform: scale(100%);
    opacity: 0;
    visibility: hidden;
  }
}

// =============================================
// Fade

@keyframes fade-in {
  0% {
    opacity: 0;
    visibility: visible;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    visibility: visible;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}

// =============================================
// Visible

@keyframes visible-in {
  0% {
    visibility: visible;
  }
  100% {
    visibility: visible;
  }
}

@keyframes visible-out {
  0% {
    visibility: visible;
  }
  100% {
    visibility: hidden;
  }
}

// =============================================
// Float

@keyframes float {
  0% {
    // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    translate: 0 0;
  }
  50% {
    // box-shadow: 0 25px 15px 0px rgba(0,0,0,0.2);
    translate: 0 20px;
  }
  100% {
    // box-shadow: 0 5px 15px 0px rgba(0,0,0,0.6);
    translate: 0 0;
  }
}

// =============================================
