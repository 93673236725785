/* 
Note: This is used from useStory.ts -> getGameSafeArea, 
to determine the vertical limits where bubbles and choices can be rendered 
*/
#game-safe-areas {
  /* disable this line for visual debugging */
  display: none;

  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  opacity: 0.5;
  z-index: 999;

  #game-safe-area-top {
    background-color: greenyellow;
    width: 100%;
    height: calc(env(safe-area-inset-top) * 1);
  }

  #game-safe-area-bottom {
    background-color: red;
    width: 100%;
    height: calc(env(safe-area-inset-bottom) * 1);
  }
}

#root {
  overflow: hidden !important;

  background-color: var(--bg-color-body);
  color: var(--text-color);

  position: relative;
  margin: 0 auto;

  // box-sizing: border-box; // note -> this will screw pwa full screen!
  // border-top: 2px solid red;
  // border-bottom: 2px solid red;

  // Note:
  // For things to be really responsive on all desktop, normal browser and pwa modes
  // min-height needs to be 100svh (as opposite to 100vh);
  // and then we need to grow the padding-bottom to fill the pwa full screen
  // otherwise, using just 100vh works in pwa, but normal browser does not account for browser navbars

  height: 100%;
  min-height: 100svh;
  width: 100%;
  max-width: var(--app-max-width);

  padding-bottom: calc(env(safe-area-inset-bottom) * 1.5);

  animation: root-enter 0.4s ease-in-out 0.2s both;
}

:root.desktop {
  // desktop layout has a fixed bar on top, and all content is pushed down
  #root {
    top: calc(var(--desktop-navbar-height) + 0px);

    min-height: calc(100svh - var(--desktop-navbar-height) - 0px);
    height: calc(100svh - var(--desktop-navbar-height) - 0px);
  }
}

:root.mobile {
  #root {
    top: 0;
  }
}

.game-app {
  height: 100%;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gemz-cookie-popup {
  p {
    margin: 5px 0;
    font-weight: bold;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
  #rcc-decline-button {
    background-color: #fff !important;
    color: #000 !important;
  }
}
