$safe-top: calc(env(safe-area-inset-top) * 1);
.craft-info {
  // background-color: rgba(255, 255, 0, 0.5);

  pointer-events: none;
  z-index: 3;

  position: absolute;
  top: 55px;

  width: 100%;
  max-width: var(--app-max-width);
  height: 100%;

  font-family: 'Pangram Medium', sans-serif;
  font-size: 25px;
  color: white;
  text-align: center;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

  .craft-sidebar {
    pointer-events: auto;
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
}

.button-ellipsis {
  pointer-events: auto;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 20px;
  width: 40px;
  height: 20px;

  .label {
    pointer-events: none;
    height: fit-content;
    margin-top: -10px;
  }
}
